jQuery(document).ready(function($) {
	if( isBreakpoint('xs') ){
		jQuery('.navbar-toggle').on('click', function(e){
			e.preventDefault();
			var $this = jQuery(this);

			if( $this.data('toggle')=='slide-collapse' ){
				var target = jQuery( $this.data('target') );
				
				if( target.hasClass('in') ){
					target.removeClass('in');
					target.animate({
						left: '-100%'
					});
				}
				else{
					var newHeight = jQuery(window).height() - jQuery('.navbar-header').height() - parseInt(target.css('paddingBottom'));
					target.addClass('in');
					target.animate({
						left: -15
					});

					target.height( newHeight );
					target.find('.primary-nav').height( newHeight );
				}
			}
		});
	}

	jQuery('.search-toggle').on('click', function(e){
		e.preventDefault();

		if( isBreakpoint('xs') ){
			var searchBox = jQuery('.navbar-search');

			jQuery('.navbar-default').removeClass('nav-up').addClass('nav-down');

			if( searchBox.hasClass('open') ){
				searchBox.slideUp().removeClass('open');
			}
			else{
				searchBox.slideDown().addClass('open');
				searchBox.find('input.form-control').focus();
			}
		}
		else{
			var searchBox = jQuery('.navbar-collapse > .navbar-form');

			if( searchBox.hasClass('open') ){
				if( searchBox.find('.form-control').val()=='' ){
					searchBox.removeClass('open');
				}
				else{
					searchBox.find('.search-submit').click();
				}
			}
			else{
				searchBox.addClass('open');
				searchBox.find('.form-control').focus();
			}
		}
	});
});
