function gridMasonry(){
	var solutionsGrid = jQuery('.solutions-list > .container-fluid > .row');

	solutionsGrid.each(function(){
		var $this = jQuery(this);

		if( $this.find('.grid-item-4') || $this.find('.grid-item-5') ){
			if( isBreakpoint('md') || isBreakpoint('lg') ){
				var marginTop = ( $this.find('.grid-item-3').height() - $this.find('.grid-item-2').height() ) * -1;
				$this.find('.grid-item-4').css('marginTop', marginTop);
				$this.css('opacity', 1);
			}
			else{
				$this.find('.grid-item-4').css('marginTop', 0);
			}
		}
	});
}

jQuery(document).ready(function($) {
	jQuery('.solutions-list > .container-fluid > .row').each(function(index){
		var rowIndex = index + 1;
		$(this).css('top', '-' + index + 'px');
	});

	jQuery('.solutions-list > .container-fluid > .row').imagesLoaded(function(){
		gridMasonry();
	});

	$('.solutions-filters-toggle').click(function(){
		if( !$(this).hasClass('open') ){
			jQuery.scrollLock( true );
			$(this).addClass('open');

			$('.solutions-filters').css({
				top: parseInt($(this).offset().top) + parseInt($(this).outerHeight()) - 1
			});
			$('.solutions-filters').css({
				height: parseInt($(window).height()) - parseInt($('.solutions-filters').css('top'))
			});

			$('.solutions-filters').addClass('open');
		}
		else{
			jQuery.scrollLock( false );
			$(this).removeClass('open');
			$('.solutions-filters').removeClass('open');
		}
	});

	$('.solutions-filters .custom-select').SumoSelect();
});

jQuery(window).resize(function(){
	gridMasonry();
});
