jQuery(document).ready(function($) {
	$('.solutions-detail .solution-video .yt-hd-thumbnail').youTubeHDThumbnail({
		darkenThumbnail: true
	});

	$('.solutions-detail .solution-gallery .slick').slick({
		dots: true,
		nav: false,
		arrows: false,
		adaptiveHeight: false,
		infinite: true
	});
});
