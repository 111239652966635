jQuery(document).ready(function($) {
	$('.product-top .product-photo .slick').slick({
		dots: false,
		arrows: false,
		draggable: false,
		fade: true,
		autoplay: true,
		autoplaySpeed: 4000
	});

	$('.product-gallery .mobile-gallery .slick').slick({
		dots: false,
		nav: false,
		arrows: false,
		adaptiveHeight: false,
		infinite: false,
		slidesToShow: 3,
		slidesToScroll: 3,
		responsive: [
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1.5,
					slidesToScroll: 1
				}
			}
		]
	});

	if( !isBreakpoint('xs') ){
		$('.product-related .slick').slick({
			dots: false,
			nav: false,
			arrows: true,
			adaptiveHeight: false,
			infinite: true,
			slidesToShow: 3,
			slidesToScroll: 1,
			prevArrow: '<span class="slick-prev"><span class="glyphicon glyphicon-menu-left"></span></span>',
			nextArrow: '<span class="slick-next"><span class="glyphicon glyphicon-menu-right"></span></span>',
		});
	}

	$('.modal_finiture').each(function(){
		$(this).find('.slider-finiture .slick').slick({
			dots: false,
			nav: false,
			arrows: true,
			adaptiveHeight: false,
			infinite: true,
			fade: false,
			prevArrow: '<span class="slick-prev"><span class="glyphicon glyphicon-menu-left"></span></span>',
			nextArrow: '<span class="slick-next"><span class="glyphicon glyphicon-menu-right"></span></span>',
			slidesToShow: 1,
			slidesToScroll: 1,
			centerMode: true,
	 		variableWidth: true
		});
	})
	$('.modal_finiture').on('shown.bs.modal', function (e) {
		$(this).find('.slider-finiture .slick').resize();
		$(this).find('.slider-finiture .slick').slick('slickGoTo', 0);
	});
});
