jQuery(document).ready(function($) {

	$('.home-intro .mobile-carousel .slick').slick({
		dots: false,
		nav: false,
		arrows: false,
		adaptiveHeight: false,
		infinite: false,
		slidesToShow: 3,
		slidesToScroll: 3,
		responsive: [
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1.5,
					slidesToScroll: 1
				}
			}
		]
	});

	$('.home-solutions .carousel .slick').slick({
		dots: true,
		nav: false,
		arrows: false,
		adaptiveHeight: false,
		infinite: true
	});

	$('.home-intro .intro-left .slick').slick({
		dots: true,
		nav: false,
		arrows: false,
		adaptiveHeight: false,
		infinite: false
	});

	$('.home-intro .intro-left .slick .item.video .yt-hd-thumbnail').youTubeHDThumbnail({
		darkenThumbnail: true
	});
});
