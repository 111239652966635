jQuery(document).ready(function($) {
	$('.news-gallery .slick').slick({
		dots: false,
		arrows: false,
		draggable: false,
		fade: true,
		autoplay: true,
		autoplaySpeed: 4000
	});

	if( isBreakpoint('md') || isBreakpoint('lg') ){
		$('.news-sidebar .list .slick').slick({
			dots: false,
			arrows: true,
			draggable: false,
			fade: false,
			autoplay: false,
			autoplaySpeed: 4000,
			slidesToShow: 7,
			slidesToScroll: 1,
			prevArrow: '<span class="slick-prev"><span class="glyphicon glyphicon-menu-up"></span></span>',
			nextArrow: '<span class="slick-next"><span class="glyphicon glyphicon-menu-down"></span></span>',
			vertical: true,
			infinite: false
		});
	}

	$('.mobile-sidebar-toggle').click(function(){
		if( !$(this).hasClass('open') ){
			jQuery.scrollLock( true );
			$(this).addClass('open');

			$('.news-sidebar').css({
				top: parseInt($(this).offset().top) + parseInt($(this).outerHeight()),
				height: parseInt($(window).height()) - parseInt($('.header .navbar').outerHeight()) - parseInt($(this).outerHeight())
			});

			$('.news-sidebar').addClass('open');
		}
		else{
			jQuery.scrollLock( false );
			$(this).removeClass('open');
			$('.news-sidebar').removeClass('open');
		}
	});
});
