jQuery(document).ready(function($) {
	function bindNavbar() {
		if ( isBreakpoint('sm') || isBreakpoint('md') || isBreakpoint('lg') || isBreakpoint('xlg') ) {
			jQuery('.primary-nav .dropdown').on('mouseover', function(){
				jQuery(this).addClass('open');
				jQuery('.dropdown-toggle', this).next('.dropdown-menu').show();
			}).on('mouseout', function(){
				jQuery(this).removeClass('open');
				jQuery('.dropdown-toggle', this).next('.dropdown-menu').hide();
			});

			jQuery('.secondary-nav .dropdown').on('mouseover', function(){
				jQuery(this).addClass('open');
				jQuery('.dropdown-toggle', this).next('.dropdown-menu').show();
			}).on('mouseout', function(){
				jQuery(this).removeClass('open');
				jQuery('.dropdown-toggle', this).next('.dropdown-menu').hide();
			});
			
			jQuery('.dropdown-toggle').click(function() {
				if (jQuery(this).next('.dropdown-menu').is(':visible') && !jQuery(this).parent().hasClass('products-dropdown')) {
					window.location = jQuery(this).attr('href');
					// window.location = jQuery(this).next('.dropdown-menu').children('li').eq(1).find('a').attr('href');
				}
			});
		}
		else {
			jQuery('.primary-nav .dropdown').off('mouseover').off('mouseout');
			jQuery('.secondary-nav .dropdown').off('mouseover').off('mouseout');
		}
	}

	if( !Modernizr.touch ){
		jQuery(window).resize(function() {
			// bindNavbar();
		});
		
		// bindNavbar();
	}

	if( !isBreakpoint('xs') ){
		$('.secondary-nav .search-trigger').click(function(e){
			$(this).next().toggleClass('open');
		});

		$('.navbar a.dropdown-toggle').on('click', function(e) {
			e.preventDefault();

			if(!jQuery(this).parent().parent().hasClass('nav')) {
				window.location = jQuery(this).attr('href');
			}
		});
	}
	else{
		$('.navbar a.dropdown-toggle').on('click', function(e) {
			var $el = $(this);
			var $parent = $(this).offsetParent(".dropdown-menu");
			$(this).parent("li").toggleClass('open');

			if(!$parent.parent().hasClass('nav')) {
				$el.next().toggle();
				$parent.toggleClass('open');
			}

			$('.nav li.open').not($(this).parents("li")).removeClass("open");

			return false;
		});
	}
});
