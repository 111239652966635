jQuery(document).ready(function($) {
	$('.system-gallery .system-video .yt-hd-thumbnail').youTubeHDThumbnail({
		darkenThumbnail: true
	});

	var slickOptions = {
		dots: false,
		nav: false,
		arrows: true,
		adaptiveHeight: false,
		infinite: true,
		slidesToShow: 6,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1
				}
			}
		]
	}

	$('.system.active .system-related .slick').slick(slickOptions);

	$('.image-1 .tip .pulse-button').click(function(e){
		e.preventDefault();

		if( !$(this).closest('.tip').find('.caption').is(':visible') ){
			$('.image-1 .tip .caption').hide();
			$(this).closest('.tip').find('.caption').show();
		}
		else{
			$(this).closest('.tip').find('.caption').hide();
		}
	});

	$('.image-2 .marker .pulse-button').click(function(e){
		e.preventDefault();

		if( !$(this).closest('.marker').find('.caption').is(':visible') ){
			$('.image-2 .marker .caption').hide();
			$(this).closest('.marker').find('.caption').show();
		}
		else{
			$(this).closest('.marker').find('.caption').hide();
		}
	});

	$('.show-system').click(function(e){
		e.preventDefault();

		$('.system.active .system-related .slick').slick('unslick');

		$('.system-details .system.active').removeClass('active');
		$( $(this).data('system') ).addClass('active');

		$('.system.active .system-related .slick').slick(slickOptions);

		$('html, body').animate({
			scrollTop: $( $(this).data('system') ).offset().top - 55
		}, 500);
	});
});
