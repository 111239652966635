jQuery(document).ready(function($) {
	$('.contacts-form select').SumoSelect();
	$('.work-form input[type=file]').customFile();

	$('.about-timeline .mobile-timeline .slick').slick({
		dots: false,
		nav: false,
		arrows: false,
		adaptiveHeight: false,
		infinite: false,
		slidesToShow: 2.5,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1.5,
					slidesToScroll: 1
				}
			}
		]
	});

	$('.about-timeline .desktop-timeline .slick').slick({
		dots: false,
		arrows: false,
		draggable: false,
		fade: true,
		autoplay: false,
		autoplaySpeed: 4000
	});

	$('.about-timeline .timeline-nav a').click(function(e){
		e.preventDefault();

		$('.about-timeline .timeline-nav a.active').removeClass('active');
		$(this).addClass('active');
		$('.about-timeline .desktop-timeline .slick').slick('slickGoTo', parseInt( $(this).data('slide-index') ), false);
	});

	$('.about-timeline .desktop-timeline h4 > span').click(function(e){
		e.preventDefault();

		var container = $(this).closest('.caption').parent();
		if( !container.hasClass('open') ){
			container.addClass('open');
			container.find('.caption .show-on-click').slideDown();
		}
		else{
			container.removeClass('open');
			container.find('.caption .show-on-click').slideUp();
		}
	});

	if( $('.page-gmap').length>0 ){
		var markers = new Array();

		$.getScript('https://maps.googleapis.com/maps/api/js?key=' + gMapsApiKey, function( data, textStatus, jqxhr ){
			$('.page-gmap').each(function(index){
				var map,
					marker,
					draggableOpt = true,
					$this = $(this),
					lat = $this.data('lat'),
    				lng = $this.data('lng'),
					centerLat = $this.data('center-lat'),
    				centerLng = $this.data('center-lng'),
    				zoom = $this.data('zoom'),
    				infoWindows = [];

    			if( typeof $this.data('center-lat')=='undefined' && typeof $this.data('center-lng')=='undefined' ){
    				centerLat = 0;
    				centerLng = 0;
    			}

    			if( typeof $this.data('zoom')=='undefined' ){
    				zoom = 2;
    			}

				$this.append('<div id="map_' + index + '"></div>');

				map = new google.maps.Map(document.getElementById('map_' + index), {
					zoom: zoom,
					center: {
						lat: centerLat,
						lng: centerLng
					},
					draggable: true,
					scrollwheel: false,
					
					zoomControl: true,
					mapTypeControl: false,
					scaleControl: false,
					streetViewControl: false,
					rotateControl: false,
					fullscreenControl: true,

					zoomControlOptions: {
						style: google.maps.ZoomControlStyle.SMALL,
						position: google.maps.ControlPosition.LEFT_TOP
					},
					streetViewControlOptions: {
						position: google.maps.ControlPosition.LEFT_TOP
					},
					styles: [
					    {
					        "featureType": "water",
					        "elementType": "geometry",
					        "stylers": [
					            {
					                "color": "#e9e9e9"
					            },
					            {
					                "lightness": 17
					            }
					        ]
					    },
					    {
					        "featureType": "landscape",
					        "elementType": "geometry",
					        "stylers": [
					            {
					                "color": "#f5f5f5"
					            },
					            {
					                "lightness": 20
					            }
					        ]
					    },
					    {
					        "featureType": "road.highway",
					        "elementType": "geometry.fill",
					        "stylers": [
					            {
					                "color": "#ffffff"
					            },
					            {
					                "lightness": 17
					            }
					        ]
					    },
					    {
					        "featureType": "road.highway",
					        "elementType": "geometry.stroke",
					        "stylers": [
					            {
					                "color": "#ffffff"
					            },
					            {
					                "lightness": 29
					            },
					            {
					                "weight": 0.2
					            }
					        ]
					    },
					    {
					        "featureType": "road.arterial",
					        "elementType": "geometry",
					        "stylers": [
					            {
					                "color": "#ffffff"
					            },
					            {
					                "lightness": 18
					            }
					        ]
					    },
					    {
					        "featureType": "road.local",
					        "elementType": "geometry",
					        "stylers": [
					            {
					                "color": "#ffffff"
					            },
					            {
					                "lightness": 16
					            }
					        ]
					    },
					    {
					        "featureType": "poi",
					        "elementType": "geometry",
					        "stylers": [
					            {
					                "color": "#f5f5f5"
					            },
					            {
					                "lightness": 21
					            }
					        ]
					    },
					    {
					        "featureType": "poi.park",
					        "elementType": "geometry",
					        "stylers": [
					            {
					                "color": "#dedede"
					            },
					            {
					                "lightness": 21
					            }
					        ]
					    },
					    {
					        "elementType": "labels.text.stroke",
					        "stylers": [
					            {
					                "visibility": "on"
					            },
					            {
					                "color": "#ffffff"
					            },
					            {
					                "lightness": 16
					            }
					        ]
					    },
					    {
					        "elementType": "labels.text.fill",
					        "stylers": [
					            {
					                "saturation": 36
					            },
					            {
					                "color": "#333333"
					            },
					            {
					                "lightness": 40
					            }
					        ]
					    },
					    {
					        "elementType": "labels.icon",
					        "stylers": [
					            {
					                "visibility": "off"
					            }
					        ]
					    },
					    {
					        "featureType": "transit",
					        "elementType": "geometry",
					        "stylers": [
					            {
					                "color": "#f2f2f2"
					            },
					            {
					                "lightness": 19
					            }
					        ]
					    },
					    {
					        "featureType": "administrative",
					        "elementType": "geometry.fill",
					        "stylers": [
					            {
					                "color": "#fefefe"
					            },
					            {
					                "lightness": 20
					            }
					        ]
					    },
					    {
					        "featureType": "administrative",
					        "elementType": "geometry.stroke",
					        "stylers": [
					            {
					                "color": "#fefefe"
					            },
					            {
					                "lightness": 17
					            },
					            {
					                "weight": 1.2
					            }
					        ]
					    }
					]
				});
				
				var bounds = new google.maps.LatLngBounds();

				$this.find('.marker').each(function(index){
					var markerPng = $(this).data('png'),
    					markerSvg = $(this).data('svg'),
						markerWidth = $(this).data('width'),
    					markerHeight = $(this).data('height'),
    					group = $(this).data('group'),
						is_internetExplorer11= navigator.userAgent.toLowerCase().indexOf('trident') > -1,
						marker_url = ( is_internetExplorer11 ) ? markerPng : markerSvg;

					var infowindow = new google.maps.InfoWindow({
						content: $(this).html()
					});

					var marker = new google.maps.Marker({
						position: new google.maps.LatLng($(this).data('lat'), $(this).data('lng')),
						map: map,
						icon: {
							url: marker_url,
							size: new google.maps.Size(markerWidth, markerHeight),
							anchor: new google.maps.Point(0, markerHeight)
						},
						zIndex: $(this).data('zindex')
					});

					marker.addListener('click', function() {
						for (var i=0;i<infoWindows.length;i++) {
							infoWindows[i].close();
						}
						infoWindows.push(infowindow);
						infowindow.open(map, marker);
					});

					markers[index] = {
						'group': group,
						'marker': marker
					}

					bounds.extend( marker.position );
				});

				if( markers.length>0 ){
					if( typeof $this.data('center-lat')=='undefined' && typeof $this.data('center-lng')=='undefined' ){
						var extendPoint = new google.maps.LatLng(bounds.getNorthEast().lat() + 0.05, bounds.getNorthEast().lng() + 0.05);
						bounds.extend(extendPoint);
						
						map.fitBounds(bounds);

						if( typeof $this.data('zoom')!='undefined' ){
							var listener = google.maps.event.addListener(map, "idle", function () {
								map.setZoom(zoom);
								google.maps.event.removeListener(listener);
							});
						}
					}
				}
			});
		});
	}
});
