jQuery(document).ready(function($) {
	$('.list-description .text .show-more').click(function(e){
		e.preventDefault();

		$(this).parent().addClass('open');
	});

	$('.list-description .text .show-less').click(function(e){
		e.preventDefault();

		$(this).parent().removeClass('open');
	});
});
